/*
Template Name: Material Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. Posts
 *	7. Widgets
 *	8. Custom Templates
 */
/******************* 
Global Styles 
*******************/
$dorado: #BE8A3E;

* {
  outline   : none;
  box-sizing: border-box;
}

body {
  font-family: $bodyfont;
  margin     : 0;
  color      : $bodytext;
  font-weight: 300;
  background : $white;
}

html {
  position  : relative;
  min-height: 100%;
  background: $white;
}

a:hover,
a:focus,
a {
  text-decoration: none;
}

a.link {
  color          : $headingtext;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $themecolor;
  }
}

.img-responsive {
  width  : 100%;
  height : auto;
  display: inline-block;
}

.img-fluid {
  max-width: 100%;
  height   : auto;
}

.img-rounded {
  border-radius: $radius;
}

html body .mdi:before,
html body .mdi-set {
  line-height: initial;
}

code {
  padding         : 0.2rem 0.4rem;
  font-size       : 90%;
  color           : $danger;
  background-color: $light-danger;
  border-radius   : 0.25rem;
}

.shadow-none {
  box-shadow: none !important;
}

.ml-auto {
  margin-left: auto;
}

.m-auto {
  margin: auto;
}

.mr-auto {
  margin-right: auto;
}

.rounded {
  border-radius: $radius;
}

/*******************
Headings
*******************/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headingfont;
  font-weight: 400;
  line-height: normal !important;
}

p {
  font-family: $headingfont;
  line-height: normal !important;
}

p,
label {
  font-size: .8rem;
}

h1 {
  line-height: 40px;
  font-size  : 36px;
}

h2 {
  line-height: 36px;
  font-size  : 30px;
}

h3 {
  line-height: 30px;
  font-size  : 21px;
}

h4 {
  line-height: 22px;
  font-size  : 18px;
}

h5 {
  line-height: 18px;
  font-size  : 16px;
  font-weight: 400;
}

h6 {
  line-height: 16px;
  font-size  : 14px;
  font-weight: 400;
}

html,
body {
  .display-5 {
    font-size: 3rem;
  }

  .display-6 {
    font-size: 36px;
  }
}

.box {
  border-radius: $radius;
  padding      : 10px;
}

html body {
  .dl {
    display: inline-block;
  }

  .db {
    display: block;
  }

  .op-5 {
    opacity: 0.5;
  }

  .op-3 {
    opacity: 0.3;
  }
}

.no-wrap {

  td,
  th {
    white-space: nowrap;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.d-flex {
  display: flex;
}

.no-shrink {
  flex-shrink: 0;
}

.oh {
  overflow: hidden;
}

/*******************
Blockquote
*******************/

html body blockquote {
  border     : 1px solid $border;
  border-left: 5px solid $themecolor;
  padding    : 15px;
  margin     : 20px 0;
}

.clear {
  clear: both;
}

ol li {
  margin: 5px 0;
}

/*******************
Paddings
*******************/
html body {
  .p-0 {
    padding: 0px;
  }

  .p-10 {
    padding: 10px;
  }

  .p-20 {
    padding: 20px;
  }

  .p-30 {
    padding: 30px;
  }

  .p-l-0 {
    padding-left: 0px;
  }

  .p-l-10 {
    padding-left: 10px;
  }

  .p-l-20 {
    padding-left: 20px;
  }

  .p-r-0 {
    padding-right: 0px;
  }

  .p-r-10 {
    padding-right: 10px;
  }

  .p-r-20 {
    padding-right: 20px;
  }

  .p-r-30 {
    padding-right: 30px;
  }

  .p-r-40 {
    padding-right: 40px;
  }

  .p-t-0 {
    padding-top: 0px;
  }

  .p-t-10 {
    padding-top: 10px;
  }

  .p-t-20 {
    padding-top: 20px;
  }

  .p-t-30 {
    padding-top: 30px;
  }

  .p-b-0 {
    padding-bottom: 0px;
  }

  .p-b-5 {
    padding-bottom: 5px;
  }

  .p-b-10 {
    padding-bottom: 10px;
  }

  .p-b-20 {
    padding-bottom: 20px;
  }

  .p-b-30 {
    padding-bottom: 30px;
  }

  .p-b-40 {
    padding-bottom: 40px;
  }
}

/*******************
Margin
*******************/
html body {
  .m-0 {
    margin: 0px;
  }

  .m-l-5 {
    margin-left: 5px;
  }

  .m-l-10 {
    margin-left: 10px;
  }

  .m-l-15 {
    margin-left: 15px;
  }

  .m-l-20 {
    margin-left: 20px;
  }

  .m-l-30 {
    margin-left: 30px;
  }

  .m-l-40 {
    margin-left: 40px;
  }

  .m-r-5 {
    margin-right: 5px;
  }

  .m-r-10 {
    margin-right: 10px;
  }

  .m-r-15 {
    margin-right: 15px;
  }

  .m-r-20 {
    margin-right: 20px;
  }

  .m-r-30 {
    margin-right: 30px;
  }

  .m-r-40 {
    margin-right: 40px;
  }

  .m-t-0 {
    margin-top: 0px;
  }

  .m-t-5 {
    margin-top: 5px;
  }

  .m-t-10 {
    margin-top: 10px;
  }

  .m-t-15 {
    margin-top: 15px;
  }

  .m-t-20 {
    margin-top: 20px;
  }

  .m-t-30 {
    margin-top: 30px;
  }

  .m-t-40 {
    margin-top: 40px;
  }

  .m-b-0 {
    margin-bottom: 0px;
  }

  .m-b-5 {
    margin-bottom: 5px;
  }

  .m-b-10 {
    margin-bottom: 10px;
  }

  .m-b-15 {
    margin-bottom: 15px;
  }

  .m-b-20 {
    margin-bottom: 20px;
  }

  .m-b-30 {
    margin-bottom: 30px;
  }

  .m-b-40 {
    margin-bottom: 40px;
  }
}

/*******************
vertical alignment
*******************/
html body {
  .vt {
    vertical-align: top;
  }

  .vm {
    vertical-align: middle;
  }

  .vb {
    vertical-align: bottom;
  }
}

/*******************
Opacity
*******************/
.op-5 {
  opacity: 0.5;
}

/*******************
font weight
*******************/
html body {
  .font-bold {
    font-weight: 600;
  }

  .font-bolder {
    font-weight: 700;
  }

  .font-normal {
    font-weight: normal;
  }

  .font-light {
    font-weight: 300;
  }

  .font-medium {
    font-weight: 500;
  }

  .font-16 {
    font-size: 16px;
  }

  .font-14 {
    font-size: 14px;
  }

  .font-10 {
    font-size: 10px;
  }

  .font-12 {
    font-size: 12px;
  }

  .font-18 {
    font-size: 18px;
  }

  .font-20 {
    font-size: 20px;
  }
}

/*******************
Border
*******************/
html body {
  .b-0 {
    border: none;
  }

  .b-r {
    border-right: 1px solid $border;
  }

  .b-l {
    border-left: 1px solid $border;
  }

  .b-b {
    border-bottom: 1px solid $border;
  }

  .b-t {
    border-top: 1px solid $border;
  }

  .b-all {
    border: 1px solid $border;
  }
}

/*******************
Thumb size
*******************/

.thumb-sm {
  height: 32px;
  width : 32px;
}

.thumb-md {
  height: 48px;
  width : 48px;
}

.thumb-lg {
  height: 88px;
  width : 88px;
}

.hide {
  display: none;
}

.img-circle {
  border-radius: 100%;
}

.radius {
  border-radius: $radius;
}

/*******************
Text Colors
*******************/
.text-white {
  color: $white !important;
}

.text-danger {
  color: $danger !important;
}

.text-muted {
  color: $muted !important;
}

.text-warning {
  color: $warning !important;
}

.text-success {
  color: $success !important;
}

.text-info {
  color: $info !important;
}

.text-inverse {
  color: $inverse !important;
}

html body {
  .text-blue {
    color: $blue;
  }

  .text-purple {
    color: $purple;
  }

  .text-primary {
    color: $primary;
  }

  .text-megna {
    color: $megna;
  }

  .text-dark {
    color: $bodytext;
  }

  .text-themecolor {
    color: $themecolor;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }
}

/*******************
Background Colors
*******************/

.bg-primary {
  background-color: $primary !important;
}

.bg-success {
  background-color: $success !important;
}

.bg-info {
  background-color: $info !important;
}

.bg-warning {
  background-color: $warning !important;
}

.bg-danger {
  background-color: $danger !important;
}

html body {
  .bg-megna {
    background-color: $megna;
  }

  .bg-theme {
    background-color: $themecolor;
  }

  .bg-inverse {
    background-color: $inverse;
  }

  .bg-purple {
    background-color: $purple !important;
  }

  .bg-light-primary {
    background-color: $light-primary;
  }

  .bg-light-success {
    background-color: $light-success;
  }

  .bg-light-info {
    background-color: $light-info;
  }

  .bg-light-extra {
    background-color: $extra-light;
  }

  .bg-light-warning {
    background-color: $light-warning;
  }

  .bg-light-danger {
    background-color: $light-danger;
  }

  .bg-light-inverse {
    background-color: $light-inverse;
  }

  .bg-light {
    background-color: $light;
  }

  .bg-white {
    background-color: $white;
  }
}

.img-circle {
  border-radius: 100%;
}

.no-shadow {
  box-shadow: none;
}

hr {
  border    : 0px;
  height    : 1px;
  background: $border;
}

/*******************
Rounds
*******************/
.round {
  line-height  : 48px;
  color        : $white;
  width        : 45px;
  height       : 45px;
  display      : inline-block;
  font-weight  : 400;
  text-align   : center;
  border-radius: 100%;
  background   : $info;

  img {
    border-radius: 100%;
  }
}

.round.round-info {
  background: $info;
}

.btn-block {
  display: block;
  width  : 100%;
}

.btn-lg {
  line-height: 48px !important;
}

/*******************
froms
*******************/
// .form-control {
//   line-height: 25px;
//   padding    : 10px;
//   width      : 100%;
//   font-size  : 1rem;
// }

.mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.25);
}

.mat-form-field {
  width: 100%;
}

/*******************
Mat card
*******************/
.mat-card {
  // box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
  // -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
  margin       : 15px;
  padding      : 0px !important;
  border-radius: $radius;

  .mat-card-content {
    font-size  : 1rem;
    line-height: 23px;
    padding    : 24px;
  }

  .mat-card-image {
    width : calc(100% + 0px);
    margin: 0 0px 0px 0px;
  }

  .mat-card-header {
    padding      : 12px;
    border-radius: $radius $radius 0 0;

    .mat-card-title {
      margin-bottom: 2px;
      font-size    : 20px;
    }

    .mat-card-subtitle,
    .mat-card-subtitle:not(:first-child) {
      margin-bottom: 0px;
      margin-top   : 0px;
      font-size    : 14px;
    }

    &.text-white {
      .mat-card-title {
        color: $white;
      }

      .mat-card-subtitle {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .mat-card-title {
    font-size    : 20px;
    margin-bottom: 5px;
  }

  .mat-card-subtitle {
    margin-bottom: 20px;
  }

  .mat-card-actions {
    margin: 0px !important;
  }
}

/*******************
Grid
*******************/
.row {
  margin: 0 -10px;
}

/*******************
Table
*******************/
.table {
  width     : 100%;
  text-align: left;
}

.table-rasponsive {
  overflow: auto;

  .mat-table {
    min-width: 280px;
  }

  .mat-header-row {
    .mat-header-cell {
      white-space: nowrap;
    }
  }

  .mat-cell {
    white-space: nowrap;
  }
}

/*******************
list inline
*******************/
.list-inline {
  margin-left: 0px;
  padding    : 0px;

  li {
    display     : inline-block;
    list-style  : none;
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }
}

/*******************/
/*Chat widget*/
/*******************/

.message-box {
  ul li {
    .drop-title {
      font-weight  : 500;
      padding      : 11px 20px 15px;
      border-bottom: 1px solid $border;
    }

    .nav-link {
      border-top : 1px solid $border;
      padding-top: 15px;
    }
  }

  .message-widget {
    position: relative;

    a {
      border-bottom  : 1px solid $border;
      display        : flex;
      text-decoration: none;
      padding        : 9px 15px;

      &:hover {
        background: $light;
      }

      &:last-child {
        border-bottom: 0px;
      }

      div {
        white-space: normal;
      }

      .user-img {
        width       : 45px;
        position    : relative;
        display     : inline-block;
        margin-right: 10px;

        img {
          width: 100%;
        }

        .profile-status {
          border       : 2px solid $white;
          border-radius: 50%;
          display      : inline-block;
          height       : 10px;
          left         : 33px;
          position     : absolute;
          top          : -1px;
          width        : 10px;
        }

        .online {
          background: $success;
        }

        .busy {
          background: $danger;
        }

        .away {
          background: $warning;
        }

        .offline {
          background: $warning;
        }
      }

      .mail-contnet {
        display       : inline-block;
        width         : 73%;
        vertical-align: middle;

        h5 {
          margin: 5px 0px 0;
          color : #212121;
        }

        .mail-desc,
        .time {
          font-size    : 12px;
          display      : block;
          margin       : 1px 0;
          text-overflow: ellipsis;
          overflow     : hidden;
          color        : $bodytext;
          white-space  : nowrap;
        }
      }
    }
  }
}

.add-contact {
  position: absolute !important;
  right   : 17px;
  top     : 57px;
}

.label {
  padding      : 3px 10px;
  line-height  : 13px;
  color        : #ffffff;
  font-weight  : 400;
  border-radius: 4px;
  font-size    : 75%;
}

.label-warning {
  background-color: $warning;
}

.label-red {
  background-color: $danger;
}

.label-success {
  background-color: $success;
}

.input-search .mat-form-field-wrapper {
  padding-bottom: 0;
  margin        : 0 !important;
}

.input-color {
  width: 50px;

  input.mat-input-element {
    height: 34px;
  }

  .mat-form-field-infix {
    border-top: 0 !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
    margin        : 0;
  }
}

.select-custom {
  .mat-form-field-flex {
    padding         : 0 .5rem;
    background-color: #f5f5f5;
  }
  .mat-form-field-infix {
    border-top: 4px solid transparent !important;
  }
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-size: 1rem;
}

.input-file {
  font-size: .8rem;
  padding  : 6px;
}

.input-file-280 {
  width    : 280px;
  max-width: 100%;
  font-size: .8rem;
  padding  : 6px;
}

.img-preview {
  background-size    : cover;
  background-position: center;
  width              : 150px;
  height             : 150px;
}

.img-preview-mini {
  background-size    : cover;
  background-position: center;
  width              : 100px;
  height             : 100px;
}

.mbutton-text-user {
  font-size  : .85rem;
  line-height: 25px !important;
}

.mat-card .mat-card-header-text {
  margin-left : 0;
  margin-right: 0;
  overflow    : hidden;
}

.text-inline {
  text-overflow: ellipsis;
  white-space  : nowrap;
  overflow     : hidden;
}

.btn-blanco-p,
.btn-dorado-p {
  border       : 0;
  border-radius: 8px !important;
  width        : 190px;
  padding      : 6px 5px !important;
  font-size    : 1rem;
  font-weight  : 500;
}

.btn-blanco-p {
  background-color: #fff;
  color           : $dorado !important;
  &:hover{
    background-color: $dorado;
    color: #fff !important;
  }
}

.btn-dorado-p {
  background-color: $dorado;
  color           : #fff !important;
  border: 1px solid $dorado !important;
  &:hover{
    background-color: #fff;
    color: $dorado !important;
  }
}

.btn-link-dorado{
  display: inline-block;
  font-weight: 600;
  color: $dorado !important;
  text-decoration: underline;
  transition: all .5s;

  &:hover{
    transition: all .5s;
    text-decoration: none;
  }
}

.mb-2rem {
  margin-bottom: 2rem !important;
}

.slick-prev,
.slick-next {
  width              : 22px;
  height             : 22px;
  background-position: center;
  background-size    : contain;
  background-repeat  : no-repeat;
  background-image   : url('../images/r.png');

  &::before {
    content: '';
  }

  &:hover,
  &:focus {
    background-position: center;
    background-size    : contain;
    background-repeat  : no-repeat;
    background-image   : url('../images/r.png');
  }
}

.slick-prev {
  transform: translate(0, -50%) rotateY(-180deg);
}

.d-xxl-block {
  display: none;
}

.text-dorado{
  color: $dorado !important;
}

.title-page{
  font-size: 1.5rem !important;
  line-height: normal !important;
  font-weight: bolder !important;
}

.title-addproduct {
  font-size  : 1.8rem !important;
  line-height: normal !important;
}

.subtitle-addproduct {
  font-size: 1.2rem !important;
  font-weight: bolder !important;
}

.card-resumen{
  width: 290px;
}

.banner{
  background-position: center;
  background-size: cover;

  h2{
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    text-shadow  : -3px 3px 6px rgb(0 0 0 / 50%);
    line-height: 1.1 !important;
    text-align: center;
    padding: 10rem 1rem 7rem;
  }
}

.mat-expansion-indicator::after{
  padding: 6px !important;
  color: $dorado;
}

.form-field-contact{
  .mat-form-field-underline{
    background-color: $dorado;
    height: 2px !important;
  }
  .mat-form-field-flex{
    padding: 0 1rem;
  }
  .mat-form-field-label{
    color: #212121;
  }
}

.list-options .mat-list-item-content{
  padding: 0 !important;
  .active{
    border: 1px solid $dorado;
  }
}

.bodyBlock{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 15%;
  border-radius: 8px;
  background: rgb(136 136 136 / 5%);
  > div{
    width: 3rem;
    height: 3rem;
    border-width: .5rem;
    color: #c38f46 !important;
  }
}

.sketch-picker{
  box-shadow: none !important;
}

.btnDelete{
  padding: 0;
  line-height: 1;
  color: #f00 !important;
}

.btnDownload{
  font-size: 0.7rem;
  padding: 5px 8px;
  border: 0;
  line-height: 1;
  border-radius: 15px;
  background: #be8a3e;
  color: #fff !important;
}

.color_dorado{
  color: $dorado;
}

.fondo_dialog .mat-dialog-container{
  background-color: #FFEBCF;
  border-radius: 10px;
}

.fondo_dialog2 .mat-dialog-container{
  background-color: $dorado;
  border-radius: 10px;
  padding: 0;
}

@media(min-width: 320px) {

  .btn-blanco-p,
  .btn-dorado-p {
    width    : 160px;
    padding  : 4px 5px !important;
    font-size: .9rem;
  }
}

@media(min-width: 375px){
  .banner{
    h2{
      font-size: 2rem;
      span{
        font-size: 2.4rem;
      }
    }
  }
}

@media(min-width: 425px){
  .banner{
    h2{
      font-size: 2.2rem;
      span{
        font-size: 2.7rem;
      }
    }
  }
}

@media(min-width: 768px) {
  .banner{
    h2{
      font-size: 3rem;
      padding: 10rem 1rem 5rem;
      span{
        font-size: 3.6rem;
      }
    }
  }
  .fondo_dialog2{
    width: 450px !important;
  }
}

@media(min-width: 992px) {
  .btn-blanco-p,
  .btn-dorado-p {
    width    : 160px;
    padding  : 3px 3px !important;
    font-size: .9rem;
  }
  .banner{
    h2{
      font-size: 3.5rem;
      padding: 10rem 1rem 5rem;
      span{
        font-size: 4.3rem;
      }
    }
  }
  
}

@media(min-width: 1200px){
  .banner{
    h2{
      font-size: 4rem;
      span{
        font-size: 4.9rem;
      }
    }
  }
}

@media(min-width: 1440px){
  .btn-blanco-p,
  .btn-dorado-p {
    width    : 180px;
    padding  : 5px 3px !important;
    font-size: 1rem;
  }
  p,
  label {
    font-size: .9rem;
  }
  .banner{
    h2{
      font-size: 4.5rem;
      span{
        font-size: 5.5rem;
      }
    }
  }
}

@media(min-width: 1600px) {

  .btn-blanco-p,
  .btn-dorado-p {
    width    : 200px;
    padding  : 8px 5px !important;
    font-size: 1.15rem;
  }

  .d-xxl-block {
    display: block;
  }

  .slick-prev,
  .slick-next {
    width : 30px;
    height: 30px;
  }

  .title-page{
    font-size: 1.8rem !important;
  }

  .banner{
    h2{
      font-size: 5rem;
      padding: 12rem 1rem 8rem;
      span{
        font-size: 6.2rem;
      }
    }
  }
}